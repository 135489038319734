// import Loader from 'examples/Loader'
import {
  FormGroup,
  FormLabel,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
// import moment from 'moment'
import { useEffect, useState } from "react";
import "./style..css";

function Table({ Resturants }) {
  const [dateFilterChecked, setDateFilterChecked] = useState(true);
  // const epoch = moment.unix(1581292800)
  const Conjo = [
    ["test", "5634r88943798", "chieueudch", "logo"],
    ["test", "5634r88943798", "testfilter", "logo"],
  ];
  const columns = [
    {
      name: "Logo",
      options: {
        filter: false,
      },
    },
    {
      name: "Name",
      options: {
        filter: false,
      },
    },
    {
      name: "Contact Detail",
      options: {
        filter: false,
      },
    },
    {
      name: "Address",
      options: {
        filter: false,
      },
    },
    {
      name: "Growth",
      options: {
        filter: false,
      },
    },
    {
      name: "Action",
      options: {
        filter: false,
      },
    },
  ];

  const options = {
    filter: true,
    filterType: "multiselect",
    responsive: "standard",
  };

  const onLoad = () => {};
  useEffect(async () => {
    onLoad();
  }, []);
  return (
    <MUIDataTable
      title={"Resturants"}
      data={Resturants}
      columns={columns}
      options={options}
    />
  );
}

export default Table;
