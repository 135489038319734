import MDBox from 'components/MDBox'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import React from 'react'

function Settings() {
  return (
    <DashboardLayout>
        <DashboardNavbar />
        <MDBox>Settings</MDBox>
    </DashboardLayout>
  )
}

export default Settings