import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import React, { useState, useEffect } from "react";
import {
  Checkbox,
  Chip,
  Divider,
  Grid,
  ListItem,
  ListItemText,
  Stack,
} from "@mui/material";
import { PassStyles } from "./styles";
import "./style.css";
import { Close, Menu } from "@mui/icons-material";

function Header() {
  return (
    <MDBox display="flex" p={1}>
      t
    </MDBox>
  );
}

function Pass({ design, resturant }) {
  const [view, setView] = useState();
  const [sidenav, setsidenav] = useState(false);
  const changeCssVaribales = async () => {
    document.documentElement.style.setProperty(
      "--app-topbar-bg",
      design?.background_colors?.topbar
    );
    document.documentElement.style.setProperty(
      "--app-footer-bg",
      design?.background_colors?.footer
    );
    document.documentElement.style.setProperty(
      "--app-sidbar-bg",
      design?.background_colors?.sidebar
    );
    document.documentElement.style.setProperty(
      "--app-sidebar_list-bg",
      design?.background_colors?.sidebar_list
    );
    document.documentElement.style.setProperty(
      "--app-buttons-bg",
      design?.background_colors?.buttons
    );

    document.documentElement.style.setProperty(
      "--app-topbar-text",
      design?.text_colors?.topbar
    );
    document.documentElement.style.setProperty(
      "--app-footer-text",
      design?.text_colors?.footer
    );
    document.documentElement.style.setProperty(
      "--app-sidebar_list-text",
      design?.text_colors?.sidebar_list
    );
    document.documentElement.style.setProperty(
      "--app-sidebar_list_active-text",
      design?.text_colors?.sidebar_list_active
    );
    document.documentElement.style.setProperty(
      "--app-buttons-text",
      design?.text_colors?.buttons
    );
  };
  const onLoad = () => {
    changeCssVaribales();
  };

  useEffect(onLoad, [design]);
  return (
    <MDBox>
      <MDBox
        position="relative"
        sx={{
          ...PassStyles({ ownerState: { backgroundColor: design.background } }),
          color: design.font,
        }}
      >
        <MDBox position="relative">
          <MDBox
            position="fixed"
            px={2}
            display="flex"
            justifyContent="space-between"
            className="topbar"
            alignItems="center"
            sx={{ width: "100%", height: "70px" }}
          >
            <img src={resturant?.logo} alt="logo" width={40} />
            <MDTypography variant="h6" className="topbar-text">
              {resturant?.name}
            </MDTypography>
            <Menu
              fontSize="small"
              color="white"
              sx={{ cursor: "pointer" }}
              onClick={() => setsidenav(true)}
            />
          </MDBox>
          <MDBox
            className={sidenav ? "sidnav-active" : "sidnav-close"}
            position="fixed"
            right="0px"
            top="0px"
            // sx={{ width: "230px", height: "40.5rem", background: "green" }}
          >
            {sidenav ? (
              <>
                <Close
                  color="white"
                  mt={1}
                  ml={2}
                  sx={{ cursor: "pointer" }}
                  onClick={() => setsidenav(false)}
                />
                <MDBox mt={3}>
                  <ListItem component="li">
                    <MDBox className="list-item-active">
                      <ListItemText
                        className="list-item-text-active"
                        primary={"euue"}
                      />
                    </MDBox>
                  </ListItem>
                  <ListItem component="li">
                    <MDBox className="list-item">
                      <ListItemText
                        className="list-item-text"
                        primary={"euue"}
                      />
                    </MDBox>
                  </ListItem>
                </MDBox>
              </>
            ) : (
              <></>
            )}
          </MDBox>
          <MDBox
            position="fixed"
            className="footer"
            bottom="-14rem"
            left="0px"
            sx={{ width: "100%", height: "70px", background: "red" }}
          >
            <MDBox
            pt={1.5}
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              sx={{fontSize:'15px'}}
            >
              <span className="footer-text">
                {" "}
                <b>Description:</b>test
              </span>
              <span className="footer-text">
                {" "}
                <b>Price:</b>2$
              </span>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

export default Pass;
