import MDButton from "components/MDButton";
import React, { useState } from "react";
import MDModal from "components/MDModal";
import MDBox from "components/MDBox";
import PropTypes from "prop-types";
import { Button, Stack, TextField } from "@mui/material";
import MDTypography from "components/MDTypography";
import { useAppServices, useUploadImage } from "hook/services";
// import { useAgencyInfo } from 'context/agency'
import { Edit } from "@mui/icons-material";

function ModalComponent({ open, onClose, data, handleRefresh }) {
  const AppService = useAppServices();
  const uploadImage = useUploadImage();
  const [processing,setprocessing]=useState(false)
  // const [agency] = useAgencyInfo()

  const handleSubmit = async (e) => {
    e.preventDefault();
    setprocessing(true)
    const payload = {
      name: e.target.name.value,
      _id: data._id,
    };
    console.log(payload, "payload");

    const { response } = await AppService.menu_category.update({
      payload,
      toaster: true,
    });

    console.log(response.data, "response");
    onClose();
    if (response) handleRefresh();
    setprocessing(false)
  };
  return (
    <MDModal open={open} onClose={onClose}>
      <MDTypography variant="h4">Edit Category</MDTypography>
      <MDBox>
        <MDBox component="form" onSubmit={handleSubmit} mt={2} role="form">
          <MDBox>
            <MDTypography variant="h6" mb={1}>
              Name
            </MDTypography>
            <TextField
              type="text"
              name="name"
              fullWidth
              defaultValue={data?.name}
            />
          </MDBox>
          <MDBox mt={5}>
            <MDButton variant="gradient" color="info" type="submit" fullWidth loading={processing} disabled={processing}>
              Edit
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </MDModal>
  );
}
ModalComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.objectOf(PropTypes.string).isRequired,
};
function CategoryModal({ data, handleRefresh }) {
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const closeAddProduct = () => {
    setOpenAddProduct(false);
  };
  return (
    <>
      <Edit
        color="white"
        fontSize="medium"
        sx={{ cursor: "pointer" }}
        onClick={() => setOpenAddProduct(true)}
      />
      <ModalComponent
        open={openAddProduct}
        onClose={closeAddProduct}
        data={data}
        handleRefresh={handleRefresh}
      />
    </>
  );
}

export default CategoryModal;
CategoryModal.propTypes = {
  data: PropTypes.objectOf(PropTypes.string).isRequired,
};
