import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { useAppServices } from "hook/services";
import { useAgencyInfo } from "context/agency";
import Loader from "examples/Loader";
import { Delete } from "@mui/icons-material";
import CategoryModal from "./components/CategoryModal";
import CategoryEditModal from "./components/CategoryEditModal";
import MenuModal from "./components/MenuModal";
import MenuEditModal from "./components/MenuEditModal";
import { useLocation, useNavigate } from "react-router-dom";
import ActivateModal from "../resturant/components/ActivateModal";

function Dashboard() {
  const navigate = useNavigate();
  const AppService = useAppServices();
  const { state: resturant } = useLocation();
  const [agency] = useAgencyInfo();
  const [Categories, setCategories] = useState([]);
  const [category_data, setcategory_data] = useState([]);
  const [Menu, setMenu] = useState([]);
  const [loading, setLoading] = useState(true);

  const GetCategory = async () => {
    const { response } = await AppService.menu_category.get({
      query: `resturant_id=${resturant._id}`,
    });
    console.log(response, "response");
    if (response) {
      const temp_data = [];
      response.data.forEach((element) => {
        const temp = {
          label: element.name,
          value: element._id,
        };
        temp_data.push(temp);
      });
      console.log(temp_data, "temp_data");
      setCategories(response.data);
      setcategory_data(temp_data);
      setLoading(false);
    }
  };
  const GetMenu = async () => {
    const { response } = await AppService.menu.get({
      query: `agency_id=${agency._id}`,
    });
    console.log(response, "menumenumenumenu");
    if (response) setMenu(response.data);
  };

  const handleDeleteCategory = async (id) => {
    const { response } = await AppService.menu_category.delete({
      query: `_id=${id}`,
    });
    if (response) GetCategory();
  };
  const handleDeleteMenu = async (id) => {
    const { response } = await AppService.menu.delete({
      query: `_id=${id}`,
    });
    if (response) GetMenu();
  };
  const onLoad = async () => {
    console.log(resturant, "resturant");
    await GetCategory();
    await GetMenu();
    setLoading(false);
  };
  useEffect(onLoad, []);

  return loading ? (
    <Loader />
  ) : (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox display="flex" justifyContent="flex-end" alignItems="center">
        <MenuModal
          category_data={category_data}
          resturant={resturant}
          handleRefresh={GetMenu}
        />
        <CategoryModal resturant={resturant} handleRefresh={GetCategory} />
        <MDButton
          variant="outlined"
          sx={{ marginLeft: 2 }}
          color="info"
          onClick={() => navigate("/agency/branding", { state: resturant })}
        >
          Branding
        </MDButton>
        <ActivateModal resturant={resturant} />
      </MDBox>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          {Categories.map((category) => (
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <MDTypography variant="h6" color="white">
                    {category.name}
                  </MDTypography>
                  <MDBox display="flex" alignItems="center">
                    <CategoryEditModal
                      data={category}
                      handleRefresh={GetCategory}
                    />
                    <Delete
                      color="white"
                      fontSize="medium"
                      sx={{ cursor: "pointer", ml: 1 }}
                      onClick={() => handleDeleteCategory(category._id)}
                    />
                  </MDBox>
                </MDBox>
                <Grid container spacing={2} p={4}>
                  {Menu.map((item) => {
                    const check = item.category.filter(
                      (o) => o.value === category._id
                    );
                    if (check.length) {
                      return (
                        <Grid item xs={4}>
                          <MDTypography variant="h6">{item.name}</MDTypography>
                          <MDTypography mt={2} sx={{ fontSize: "15px" }}>
                            <b>Price:</b> ${item.price}
                          </MDTypography>
                          <MDTypography sx={{ fontSize: "15px" }}>
                            <b>Description</b>: {item.description}
                          </MDTypography>
                          <MDBox mt={3}>
                            <MenuEditModal
                              category_data={category_data}
                              data={item}
                              handleRefresh={GetMenu}
                            />
                            <MDButton
                              variant="contained"
                              color="error"
                              size="small"
                              sx={{ marginLeft: 2 }}
                              onClick={() => handleDeleteMenu(item._id)}
                            >
                              Delete
                            </MDButton>
                          </MDBox>
                        </Grid>
                      );
                    }
                  })}
                </Grid>
              </Card>
            </Grid>
          ))}
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Dashboard;
