import MDButton from "components/MDButton";
import React, { useState } from "react";
import MDModal from "components/MDModal";
import MDBox from "components/MDBox";
import PropTypes from "prop-types";
import { Button, Stack, TextField, TextareaAutosize } from "@mui/material";
import MDTypography from "components/MDTypography";
import { useAppServices } from "hook/services";
import { useAgencyInfo } from "context/agency";
import { useUploadImage } from "hook/services";
import "./style.css";
import { OpenInNew } from "@mui/icons-material";
function ModalComponent({ open, onClose, handleRefresh, resturant }) {
  const AppService = useAppServices();
  const uploadImage = useUploadImage();
  const [agency] = useAgencyInfo();
  const [processing,setprocessing]=useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault();
    setprocessing(true)

    const image_url = await uploadImage({
      file: e.target.logo.files[0],
      desiredPath: `design/logo/image`,
    });
    console.log(image_url.response.data, "image_url");
    const payload = {
      markar: image_url.response.data,
      _id: resturant._id,
    };
    console.log(payload, "payload");

    const { response } = await AppService.resturant.update({
      payload,
      toaster: true,
    });

    console.log(response.data, "response");
    onClose();
    handleRefresh();
    setprocessing(false)

  };
  return (
    <MDModal open={open} onClose={onClose}>
      <MDTypography variant="h4">Activate Resturant</MDTypography>
      <MDBox>
        <MDBox component="form" onSubmit={handleSubmit} mt={2} role="form">
          <MDBox mt={3} display="flex" alignItems="center">
            <Stack direction="row" alignItems="center" spacing={2}>
              <Button
                variant="contained"
                component="label"
                sx={{ color: "#fff" }}
              >
                Upload Markar +
                <input hidden name="logo" type="file" />
              </Button>
            </Stack>
          </MDBox>
          <MDBox mt={3}>
            <MDTypography id="modal-modal-title" variant="h6" component="h2">
              Genrate Your Markar
            </MDTypography>
            <MDTypography
              variant="caption"
              display="block"
              sx={{ fontSize: "15px" }}
              gutterBottom
            >
              <b>1.</b> Visit the marker training generator website: Go to{" "}
              <a
                style={{ color: "black" }}
                href="https://jeromeetienne.github.io/AR.js/three.js/examples/marker-training/examples/generator.html"
                target="_blank"
              >
                <b>
                  {" "}
                  generator <OpenInNew />
                </b>
              </a>
            </MDTypography>
            <MDTypography
              variant="caption"
              display="block"
              sx={{ fontSize: "15px" }}
              gutterBottom
            >
              <b>2.</b>Add a marker and download the marker files in a ZIP
              format
            </MDTypography>
            <MDTypography
              variant="caption"
              display="block"
              sx={{ fontSize: "15px" }}
              gutterBottom
            >
              <b>3.</b> Upload your markar <b>.patt</b> file
            </MDTypography>
          </MDBox>
          <MDBox mt={5}>
            <MDButton variant="gradient" color="info" type="submit" fullWidth loading={processing} disabled={processing}>
              Activate
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </MDModal>
  );
}
ModalComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
function ActivateModal({ handleRefresh, resturant }) {
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const closeAddProduct = () => {
    setOpenAddProduct(false);
  };
  return (
    <>
      <MDButton
        className={resturant?.markar != undefined ? "" : "glow-button"}
        variant="contained"
        color="info"
        sx={{ marginLeft: 2 }}
        onClick={() => setOpenAddProduct(true)}
      >
        {resturant?.markar != undefined ? "Activated" : "Activate"}
      </MDButton>
      <ModalComponent
        open={openAddProduct}
        resturant={resturant}
        onClose={closeAddProduct}
        handleRefresh={handleRefresh}
      />
    </>
  );
}

export default ActivateModal;
