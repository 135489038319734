import MDButton from "components/MDButton";
import React, { useState } from "react";
import MDModal from "components/MDModal";
import MDBox from "components/MDBox";
import PropTypes from "prop-types";
import { Button, Stack, TextField } from "@mui/material";
import MDTypography from "components/MDTypography";
import { useAppServices, useUploadImage } from "hook/services";
// import { useAgencyInfo } from 'context/agency'
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import Multiselect from "../MultiSelect/Multiselect";

function ModalComponent({ open, onClose, category_data, data, handleRefresh }) {
  const AppService = useAppServices();
  const uploadImage = useUploadImage();
  // const [agency] = useAgencyInfo()
  const [processing, setprocessing] = useState(false);
  const PrettoSlider = styled(Slider)({
    color: "#52af77",
    height: 8,
    "& .MuiSlider-track": {
      border: "none",
    },
    "& .MuiSlider-thumb": {
      height: 24,
      width: 24,
      backgroundColor: "#52af77",
      border: "2px solid currentColor",
      "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
        boxShadow: "inherit",
      },
      "&:before": {
        display: "none",
      },
    },
    "& .MuiSlider-valueLabel": {
      lineHeight: 1.2,
      fontSize: 12,
      background: "unset",
      padding: 0,
      width: 32,
      height: 32,
      borderRadius: "50% 50% 50% 0",
      backgroundColor: "#52af77",
      transformOrigin: "bottom left",
      transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
      "&:before": { display: "none" },
      "&.MuiSlider-valueLabelOpen": {
        transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
      },
      "& > *": {
        transform: "rotate(45deg)",
      },
    },
  });
  const scales = [
    {
      label: "XS",
      value: "xs",
    },
    {
      label: "S",
      value: "s",
    },
    {
      label: "M",
      value: "m",
    },
    {
      label: "L",
      value: "l",
    },
    {
      label: "XL",
      value: "xl",
    },
    {
      label: "XXL",
      value: "xxl",
    },
  ];
  const handleSubmit = async (e) => {
    e.preventDefault();
    setprocessing(true);
    var image_url = data?.image;
    if (e.target.image.files[0]) {
      const { response } = await uploadImage({
        file: e.target.image.files[0],
        desiredPath: `design/logo/image`,
      });
      image_url = "https://" + response.data;
    }
    const payload = {
      name: e.target.name.value,
      description: e.target.description.value,
      price: e.target.price.value,
      category: JSON.parse(e.target.category.value),
      scale: e.target.scale.value,
      image: image_url,
      _id: data._id,
    };
    console.log(payload, "payload");

    const { response } = await AppService.menu.update({
      payload,
      toaster: true,
    });

    console.log(response.data, "response");
    onClose();
    if (response) handleRefresh();
    setprocessing(false);
  };
  return (
    <MDModal
      open={open}
      onClose={onClose}
      styling={{ maxHeight: 600, overflow: "auto" }}
    >
      <MDTypography variant="h4">Edit Item</MDTypography>
      <MDBox>
        <MDBox component="form" onSubmit={handleSubmit} mt={2} role="form">
          <MDBox>
            <MDTypography variant="h6" mb={1}>
              Name
            </MDTypography>
            <TextField
              type="text"
              name="name"
              fullWidth
              defaultValue={data?.name}
            />
          </MDBox>
          <MDBox mt={1}>
            <MDTypography variant="h6" mb={1}>
              Description
            </MDTypography>
            <TextField
              type="text"
              name="description"
              fullWidth
              defaultValue={data?.description}
            />
          </MDBox>
          <MDBox mt={1} sx={{ fontSize: "14px" }}>
            <MDTypography variant="h6" mb={1}>
              Category
            </MDTypography>
            <Multiselect
              type="multi"
              data={category_data}
              edit_data={data?.category}
              name="category"
            />
          </MDBox>
          <MDBox mt={1}>
            <MDTypography variant="h6" mb={1}>
              Price
            </MDTypography>
            <TextField
              type="number"
              name="price"
              fullWidth
              defaultValue={data?.price}
            />
          </MDBox>
          <MDBox mt={1} sx={{ fontSize: "14px" }}>
            <MDTypography variant="h6">Scale</MDTypography>
            <PrettoSlider
              valueLabelDisplay="auto"
              aria-label="pretto slider"
              defaultValue={data?.scale}
              step={15}
              min={10}
              max={100}
              name="scale"
            />
          </MDBox>
          <MDBox mt={3} display="flex" alignItems="center">
            <Stack direction="row" alignItems="center" spacing={2}>
              <Button
                variant="contained"
                component="label"
                sx={{ color: "#fff" }}
              >
                Upload GLTF File +
                <input hidden name="image" type="file" />
              </Button>
            </Stack>
          </MDBox>
          <MDBox mt={5}>
            <MDButton
              variant="gradient"
              color="info"
              type="submit"
              fullWidth
              loading={processing}
              disabled={processing}
            >
              Edit
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </MDModal>
  );
}
ModalComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  category_data: PropTypes.PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.PropTypes.arrayOf(PropTypes.object).isRequired,
};
function MenuModal({ category_data, data, handleRefresh }) {
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const closeAddProduct = () => {
    setOpenAddProduct(false);
  };
  return (
    <>
      <MDButton
        variant="contained"
        sx={{ marginRight: 2 }}
        color="info"
        onClick={() => setOpenAddProduct(true)}
      >
        Edit
      </MDButton>
      <ModalComponent
        open={openAddProduct}
        onClose={closeAddProduct}
        category_data={category_data}
        data={data}
        handleRefresh={handleRefresh}
      />
    </>
  );
}

export default MenuModal;
MenuModal.propTypes = {
  category_data: PropTypes.PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.PropTypes.arrayOf(PropTypes.object).isRequired,
};
