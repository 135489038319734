export function PassStyles({ ownerState }) {
  const { backgroundColor } = ownerState
  return {
    width: 'auto',
    minHeight: '420px',
    margin: '60px auto',
    // Flip Effect
    background: 'transparent',
    perspective: '1000px',
    '.active': {
      outline: '1px solid cornflowerblue',
    },
    '.hover': {
      outline: '2px solid cornflowerblue',
    },
    '.coupon::before': {
      display: 'block',
      content: `''`,
      background: `linear-gradient(-45deg, ${backgroundColor} 16px, red 16px, blue 16px,  transparent 0), linear-gradient(45deg, ${backgroundColor} 16px, transparent 0)`,
      backgroundPosition: 'left top',
      backgroundRepeat: 'repeat-x',
      backgroundSize: '12px 26px',
      height: '26px',
      width: '100%',
      position: 'relative',
      left: 0,
      top: '-20px',
    },
    '.coupon::after': {
      display: 'block',
      content: `''`,
      background: `linear-gradient(-45deg, transparent 16px, ${backgroundColor} 0), linear-gradient(45deg, transparent 16px, ${backgroundColor}  0)`,
      backgroundPosition: 'left bottom',
      backgroundRepeat: 'repeat-x',
      backgroundSize: '12px 26px',
      height: '26px',
      width: '100%',
      position: 'relative',
      left: 0,
      top: 'calc(100% - 28px)',
    },
    '.ticket::before': {
      display: 'block',
      content: `''`,
      height: '60px',
      width: '60px',
      backgroundColor: '#f0f2f5',
      clipPath: 'circle(50% at 50% 0)',
      position: 'relative',
      top: '-13px',
      left: 'calc(50% - 30px)',
      zIndex: 1,
    },
    '.store': {
      borderRadius: '10px',
    },
  }
}
