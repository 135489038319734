import MDButton from "components/MDButton";
import React, { useState } from "react";
import MDModal from "components/MDModal";
import MDBox from "components/MDBox";
import PropTypes from "prop-types";
import { Button, Stack, TextField, TextareaAutosize } from "@mui/material";
import MDTypography from "components/MDTypography";
import { useAppServices } from "hook/services";
import { useAgencyInfo } from "context/agency";
import { useUploadImage } from "hook/services";

function ModalComponent({ open, onClose, handleRefresh }) {
  const AppService = useAppServices();
  const uploadImage = useUploadImage();
  const [agency] = useAgencyInfo();
  const [processing,setprocessing]=useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault();
    setprocessing(true)
    const image_url = await uploadImage({
      file: e.target.logo.files[0],
      desiredPath: `design/logo/image`,
    });
    console.log(image_url.response.data, "image_url");
    const payload = {
      name: e.target.name.value,
      email: e.target.email.value,
      phone: e.target.phone.value,
      address: e.target.address.value,
      logo: image_url.response.data,
      agency_id: agency._id,
    };
    console.log(payload, "payload");

    const { response } = await AppService.resturant.create({
      payload,
      toaster: true,
    });

    console.log(response.data, "response");
    setprocessing(false)
    onClose();
    handleRefresh();
  };
  return (
    <MDModal open={open} onClose={onClose}>
      <MDTypography variant="h4">Create Resturant</MDTypography>
      <MDBox>
        <MDBox component="form" onSubmit={handleSubmit} mt={2} role="form">
          <MDBox>
            <MDTypography variant="h6" mb={0.5}>
              Name
            </MDTypography>
            <TextField type="text" name="name" fullWidth />
          </MDBox>
          <MDBox mt={0.5}>
            <MDTypography variant="h6" mb={0.5}>
              Email
            </MDTypography>
            <TextField type="email" name="email" fullWidth />
          </MDBox>
          <MDBox mt={0.5}>
            <MDTypography variant="h6" mb={0.5}>
              Phone
            </MDTypography>
            <TextField type="tel" name="phone" fullWidth />
          </MDBox>
          <MDBox mt={0.5}>
            <MDTypography variant="h6" mb={0.5}>
              Address
            </MDTypography>
            <TextareaAutosize
              type="text"
              name="address"
              minRows={5}
              style={{ width: "100%" }}
            />
          </MDBox>
          <MDBox mt={3} display="flex" alignItems="center">
            <Stack direction="row" alignItems="center" spacing={2}>
              <Button
                variant="contained"
                component="label"
                sx={{ color: "#fff" }}
              >
                Upload Logo +
                <input hidden name="logo" type="file" />
              </Button>
            </Stack>
          </MDBox>
          <MDBox mt={5}>
            <MDButton variant="gradient" color="info" type="submit" fullWidth loading={processing} disabled={processing}>
              Add
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </MDModal>
  );
}
ModalComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
function CategoryModal({ handleRefresh }) {
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const closeAddProduct = () => {
    setOpenAddProduct(false);
  };
  return (
    <>
      <MDButton
        variant="outlined"
        color="info"
        onClick={() => setOpenAddProduct(true)}
      >
        create
      </MDButton>
      <ModalComponent
        open={openAddProduct}
        onClose={closeAddProduct}
        handleRefresh={handleRefresh}
      />
    </>
  );
}

export default CategoryModal;
