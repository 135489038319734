import MDButton from "components/MDButton";
import React, { useState } from "react";
import MDModal from "components/MDModal";
import MDBox from "components/MDBox";
import PropTypes from "prop-types";
import { Button, Stack, TextField } from "@mui/material";
import MDTypography from "components/MDTypography";
import { useAppServices, useUploadImage } from "hook/services";
import { useAgencyInfo } from "context/agency";

function ModalComponent({ open, onClose, resturant,handleRefresh }) {
  const AppService = useAppServices();
  const uploadImage = useUploadImage();
  const [agency] = useAgencyInfo();
  const [processing,setprocessing]=useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault();
    setprocessing(true)
    const payload = {
      name: e.target.name.value,
      agency_id: agency._id,
      resturant_id: resturant._id,
    };
    console.log(payload, "payload");

    const { response } = await AppService.menu_category.create({
      payload,
      toaster: true,
    });

    console.log(response.data, "response");
    onClose();
    if(response) handleRefresh()
    setprocessing(false)

  };
  return (
    <MDModal open={open} onClose={onClose}>
      <MDTypography variant="h4">Create</MDTypography>
      <MDBox>
        <MDBox component="form" onSubmit={handleSubmit} mt={2} role="form">
          <MDBox>
            <MDTypography variant="h6" mb={1}>
              Name
            </MDTypography>
            <TextField type="text" name="name" fullWidth />
          </MDBox>
          <MDBox mt={5}>
            <MDButton variant="gradient" color="info" type="submit" fullWidth loading={processing} disabled={processing}>
              Add
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </MDModal>
  );
}
ModalComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
function CategoryModal({ resturant ,handleRefresh}) {
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const closeAddProduct = () => {
    setOpenAddProduct(false);
  };
  return (
    <>
      <MDButton
        variant="outlined"
        color="info"
        onClick={() => setOpenAddProduct(true)}
      >
        create category
      </MDButton>
      <ModalComponent
        open={openAddProduct}
        onClose={closeAddProduct}
        resturant={resturant}
        handleRefresh={handleRefresh}
      />
    </>
  );
}

export default CategoryModal;
