import axios from "axios";
import env from "config";
import { setNotification, useNotifcation } from "context/notification";
import { ReqMethods, ResponseStatus } from "enums";
import { useAuth, useLogout } from "./auth";

const BASE_URL = `${env.API_URL}/v1`;

function useServiceHandler() {
  const [, dispatch] = useNotifcation();
  const Logout = useLogout();

  return (fn) =>
    async (
      method,
      path,
      { query, payload, token, apiKey, toaster, message, error } = {}
    ) => {
      try {
        const res = await fn(method, path, { query, payload, token, apiKey });
        console.log("API - RESPONSE", res, toaster, message, error);
        toaster &&
          setNotification(dispatch, {
            open: true,
            message: message || res.data.message,
            title: "Success",
          });

        return { response: res.data };
      } catch (err) {
        console.log("API- ERROR", err.response?.data || err);

        // expire error : jwt expired
        if (
          err.response &&
          err.response.status === ResponseStatus.UNAUTHORIZED
        ) {
          setNotification(dispatch, {
            open: true,
            message: error || "session expired",
            title: "Error",
            severity: "error",
          });
          setTimeout(Logout, 4000);
          return { error: err.response?.data || err };
        }

        const customError = err.response?.data?.error
          ? `${err.response?.data?.message} \n${err.response?.data?.error}`
          : err.response?.data?.message;

        toaster &&
          setNotification(dispatch, {
            open: true,
            message: error || customError || err.message,
            severity: "error",
            title: "Error",
          });
        return { error: err.response ? err.response.data : err };
      }
    };
}

function useCallService() {
  const authToken = useAuth();
  const serviceHandler = useServiceHandler();

  const CallService = (
    method,
    path,
    { query, payload, token = true, apiKey = null }
  ) => {
    const pathname = query ? `${path}?${query}` : path;
    const config = {};

    if (token)
      config.headers = { "x-auth-token": `Bearer ${authToken || token}` };
    if (apiKey) config.headers = { apiKey };

    const details = {};

    if (payload) details.payload = payload;
    details.config = config;

    return axios[method](pathname, ...Object.values(details));
  };

  return serviceHandler(CallService);
}

function useAppServices() {
  const { GET, POST, PUT, DELETE } = ReqMethods;
  const CallService = useCallService();

  /**
    Option for service is the object that could has the following properties
    query, payload, token, apiKey
  */

  const APIs = {
    auth: {
      login: (options) => CallService(POST, `${BASE_URL}/auth/login`, options),
    },
    utils: {
      upload_image: (options) =>
        CallService(POST, `${BASE_URL}/utils/upload/image`, options),
    },
    user: {
      get: (options) => CallService(GET, `${BASE_URL}/user`, options),
    },
    brand: {
      get: (options) => CallService(GET, `${BASE_URL}/brand`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/brand`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/brand/filter`, options),
    },
    agency: {
      get: (options) => CallService(GET, `${BASE_URL}/agency`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/agency`, options),
    },
    menu_category: {
      get: (options) => CallService(GET, `${BASE_URL}/menu_category`, options),
      create: (options) =>
        CallService(POST, `${BASE_URL}/menu_category`, options),
      delete: (options) =>
        CallService(DELETE, `${BASE_URL}/menu_category`, options),
      update: (options) =>
        CallService(PUT, `${BASE_URL}/menu_category`, options),
    },
    resturant: {
      get: (options) => CallService(GET, `${BASE_URL}/resturant`, options),
      create: (options) => CallService(POST, `${BASE_URL}/resturant`, options),
      delete: (options) =>
        CallService(DELETE, `${BASE_URL}/resturant`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/resturant`, options),
    },
    menu: {
      get: (options) => CallService(GET, `${BASE_URL}/menu`, options),
      create: (options) => CallService(POST, `${BASE_URL}/menu`, options),
      delete: (options) => CallService(DELETE, `${BASE_URL}/menu`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/menu`, options),
    },
    services: {
      ghl: {
        call_service: (options) =>
          CallService(POST, `${BASE_URL}/services/ghl/`, options),
      },
    },
  };

  return APIs;
}
const useUploadImage = () => {
  const AppService = useAppServices();
  return ({ toaster, file, desiredPath }) => {
    const form = new FormData();
    console.log(file);
    form.append("image", file, file.name);
    return AppService.utils.upload_image({
      toaster,
      payload: form,
      query: `desiredPath=${desiredPath}`,
    });
  };
};

export { useAppServices, useCallService, useUploadImage };
