import makeAnimated from 'react-select/animated'
import Select from 'react-select'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

const animatedComponents = makeAnimated()
function Multiselect({ data, edit_data, name,type }) {
  // const [data, setdata] = useState([])
  const [values, setvalues] = useState(JSON.stringify(edit_data))

  const handlechange = (e) => {
    console.log(JSON.stringify(e))
    setvalues(JSON.stringify(e))
  }
  useEffect(async () => {
    console.log(data,'data')
  }, [])

  return (
    <>
    {
      type=="multi" ?

      <Select
        closeMenuOnSelect={false}
        isMulti
        components={animatedComponents}
        defaultValue={edit_data}
        onChange={handlechange}
        options={data}
      />
      :
      <Select
        closeMenuOnSelect={true}
        components={animatedComponents}
        defaultValue={edit_data}
        onChange={handlechange}
        options={data}
      />
    }
      <input type="hidden" value={values} name={name} />
    </>
  )
}
Multiselect.propTypes = {
  name      : PropTypes.string.isRequired,
  data      : PropTypes.PropTypes.arrayOf(PropTypes.object).isRequired,
  edit_data : PropTypes.PropTypes.arrayOf(PropTypes.object).isRequired,
}
export default Multiselect
