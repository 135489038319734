// import { Card, Grid } from '@mui/material'
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { useAppServices } from "hook/services";
import { useAgencyInfo } from "context/agency";
import Loader from "examples/Loader";
import ResturantModal from "./components/ResturantModal";
import ResturantTable from "./components/ResturantTable";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import { CopyAll, Done, QrCode } from "@mui/icons-material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { RemoveRedEye } from "@mui/icons-material";
import ResturantEditModal from "./components/ResturantEditModal";
import ActivateModal from "./components/ActivateModal";

function Resturant() {
  const AppService = useAppServices();
  const navigate = useNavigate();
  const [agency] = useAgencyInfo();
  const [Resturants, setResturants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setrefresh] = useState(false);
  const [copy, setcopy] = useState(false);
  const [domain, setdomain] = useState(
    "https://arapp.livewiremediapartners.com"
  );

  const handleDelete = async (id) => {
    const { response } = await AppService.resturant.delete({
      query: `_id=${id}`,
    });
    if (response) setrefresh(!refresh);
  };
  const handleCopy = async () => {
    // alert('dh')
    setcopy(true);
    setTimeout(() => {
      setcopy(false);
    }, 3000);
  };
  const RenderAction = (data) => (
    <>
      <MDButton
        variant="contained"
        size="small"
        sx={{ mr: 1 }}
        color="warning"
        onClick={() => navigate("/agency/menu", { state: data })}
      >
        Menu
      </MDButton>
      <ResturantEditModal
        data={data}
        handleRefresh={() => setrefresh(!refresh)}
      />
      <MDButton
        variant="contained"
        size="small"
        color="error"
        onClick={() => handleDelete(data._id)}
      >
        Delete
      </MDButton>
    </>
  );
  const GrowthAction = (data) => {
    if (data?.domain != undefined && data?.domain != "") {
      return (
        <>
          <QrCode
            fontSize="medium"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              let qr_data = data?.domain;
              let url =
                "https://api.qrserver.com/v1/create-qr-code/?data=" +
                qr_data +
                "&size=200x200&margin=0&download=1";
              window.location.href = url;
            }}
          />
          <RemoveRedEye
            sx={{ marginLeft: 1, cursor: "pointer" }}
            fontSize="medium"
            onClick={() => {
              window.open(`${data?.domain}/aframe/${data._id}`, "_blank");
            }}
          />
          <CopyToClipboard
            sx={{ marginLeft: 1, cursor: "pointer" }}
            text={`${data?.domain}/aframe/${data._id}`}
            onCopy={handleCopy}
          >
            {copy == true ? (
              <Done fontSize="medium" />
            ) : (
              <CopyAll fontSize="medium" />
            )}
          </CopyToClipboard>
        </>
      );
    } else {
      return (
        <>
          <QrCode
            fontSize="medium"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              let qr_data = domain;
              let url =
                "https://api.qrserver.com/v1/create-qr-code/?data=" +
                qr_data +
                "&size=200x200&margin=0&download=1";
              window.location.href = url;
            }}
          />
          <RemoveRedEye
            sx={{ marginLeft: 1, cursor: "pointer" }}
            fontSize="medium"
            onClick={() => {
              window.open(`${domain}/aframe/${data._id}`, "_blank");
            }}
          />
          <CopyToClipboard
            sx={{ marginLeft: 1, cursor: "pointer" }}
            text={`${domain}/aframe/${data._id}`}
            onCopy={handleCopy}
          >
            {copy == true ? (
              <Done fontSize="medium" />
            ) : (
              <CopyAll fontSize="medium" />
            )}
          </CopyToClipboard>
        </>
      );
    }
  };

  const GetResturant = async () => {
    const { response } = await AppService.resturant.get({
      query: `agency_id=${agency?._id}`,
    });
    console.log(response, "Resturants");
    const data = [];
    if (response) {
      response.data.forEach((element) => {
        const temp = [
          <img src={element.logo} alt="logo" width={70} />,
          element.name,
          element.contact_detail,
          element.address,
          GrowthAction(element),
          RenderAction(element),
        ];
        data.push(temp);
      });
      setResturants(data);
      setLoading(false);
    }
  };

  const onLoad = async () => {
    GetResturant();
  };
  useEffect(onLoad, [refresh]);

  return loading ? (
    <Loader />
  ) : (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox display="flex" justifyContent="flex-end" alignItems="center">
        <ResturantModal handleRefresh={GetResturant} />
      </MDBox>
      <MDBox pt={2} pb={3}>
        <ResturantTable Resturants={Resturants} />
      </MDBox>
    </DashboardLayout>
  );
}

export default Resturant;
