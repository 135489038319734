import MDButton from "components/MDButton";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import { Grid, Card, Button, Stack, TextField } from "@mui/material";
// Settings page components
import { useAppServices, useUploadImage } from "hook/services";
import { useAgencyInfo } from "context/agency";
// Data
import { useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useLocation, useNavigate } from "react-router-dom";
import MobileImg from "../../../assets/images/cellphone-2.svg";
import Pass from "../pass";
import { ArrowBack } from "@mui/icons-material";

const index = () => {
  const AppService = useAppServices();
  const navigate = useNavigate();
  const { state: resturant } = useLocation();
  const [agency, Update] = useAgencyInfo();
  const uploadImage = useUploadImage();
  const [domain_update, setdomain_update] = useState(false);
  const [_design, set_Design] = useState({
    background_colors: {
      topbar: "#1B3358",
      footer: "#1B3358",
      sidebar: "#06143E",
      sidebar_list: "#F1916D",
      buttons: "#F1916D",
    },
    text_colors: {
      topbar: "#ffffff",
      footer: "#ffffff",
      sidebar_list: "#ffffff",
      sidebar_list_active: "#ffffff",
      buttons: "#000000",
    },
  });
  const handleChange = () => {
    setdomain_update(true);
  };
  // const handleImage = async ({ name, value }) => {
  //   const response = await uploadImage({
  //     file: value,
  //     desiredPath: `design/logo/image`,
  //   })
  //   const statedata = {
  //     logo: () => {
  //       setimage_url({
  //         logo: response.response.data,
  //         login_page: image_url?.login_page,
  //       })
  //     },
  //     login_page: () => {
  //       setimage_url({
  //         logo: image_url?.logo,
  //         login_page: response.response.data,
  //       })
  //     },
  //   }
  //   statedata[name]()
  //   console.log(response.response.data, 'logo')
  // }
  const handleValues = ({ type, name, value }) => {
    console.log(type, name, value);
    set_Design({
      background_colors: {
        topbar:
          type == "background_colors" && name == "topbar"
            ? value
            : _design.background_colors.topbar,
            footer:
          type == "background_colors" && name == "footer"
            ? value
            : _design.background_colors.footer,
        sidebar:
          type == "background_colors" && name == "sidebar"
            ? value
            : _design.background_colors.sidebar,
        sidebar_list:
          type == "background_colors" && name == "sidebar_list"
            ? value
            : _design.background_colors.sidebar_list,
        buttons:
          type == "background_colors" && name == "buttons"
            ? value
            : _design.background_colors.buttons,
      },
      text_colors: {
        topbar:
          type == "text_colors" && name == "topbar"
            ? value
            : _design.text_colors.topbar,
            footer:
            type == "text_colors" && name == "footer"
              ? value
              : _design.text_colors.footer,
        sidebar_list:
          type == "text_colors" && name == "sidebar_list"
            ? value
            : _design.text_colors.sidebar_list,
        buttons:
          type == "text_colors" && name == "buttons"
            ? value
            : _design.text_colors.buttons,
        sidebar_list_active:
          type == "text_colors" && name == "sidebar_list_active"
            ? value
            : _design.text_colors.sidebar_list_active,
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("submit");
    const payload = {
      _id: resturant._id,
      domain: e.target.domain.value,
      domain_change: domain_update,
      design: _design,
    };
    console.log(payload, "payload");

    const { response } = await AppService.resturant.update({
      payload,
      toaster: true,
    });
    setdomain_update(false);
    console.log(response, "response");
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card
        id="wallet-domain-config"
        sx={{ overflow: "visible" }}
        component="form"
        onSubmit={handleSubmit}
      >
        <MDBox p={3} display="flex" justifyContent="space-between">
          <MDBox display="flex">
            <ArrowBack
              fontSize="medium"
              sx={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
            <MDTypography variant="h5" ml={2}>
              Resturant Branding
            </MDTypography>
          </MDBox>
          <MDButton variant="gradient" color="info" type="submit">
            update
          </MDButton>
        </MDBox>
        <MDBox>
          <Grid container spacing={3} paddingX={3} paddingBottom={3}>
            <Grid item xs={7}>
              <Grid container spacing={3} paddingX={3} paddingBottom={3}>
                <Grid item xs={12}>
                  <MDTypography variant="h6" mb={1}>
                    Domain
                  </MDTypography>
                  <input
                    type={"hidden"}
                    name={"domain_change"}
                    defaultValue={domain_update}
                  />
                  <TextField
                    defaultValue={resturant?.domain}
                    type="text"
                    name="domain"
                    onChange={() => setdomain_update(true)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <MDBox>
                    <MDTypography variant="h6">Backgrounds Colors</MDTypography>
                  </MDBox>
                </Grid>

                <Grid item sm={6} xs={12}>
                  <MDInput
                    value={_design?.background_colors?.topbar}
                    type="color"
                    label="TopBar"
                    fullWidth
                    className="color-input-2"
                    onChange={(e) => {
                      handleValues({
                        type: "background_colors",
                        name: "topbar",
                        value: e.target.value,
                      });
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <MDInput
                    value={_design?.background_colors?.footer}
                    type="color"
                    label="Footer"
                    fullWidth
                    className="color-input-2"
                    onChange={(e) => {
                      handleValues({
                        type: "background_colors",
                        name: "footer",
                        value: e.target.value,
                      });
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <MDInput
                    type="color"
                    value={_design?.background_colors?.sidebar}
                    label="Sidebar"
                    fullWidth
                    className="color-input-2"
                    onChange={(e) => {
                      handleValues({
                        type: "background_colors",
                        name: "sidebar",
                        value: e.target.value,
                      });
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <MDInput
                    type="color"
                    value={_design?.background_colors?.sidebar_list}
                    label="Sidebar List"
                    fullWidth
                    className="color-input-2"
                    onChange={(e) => {
                      handleValues({
                        type: "background_colors",
                        name: "sidebar_list",
                        value: e.target.value,
                      });
                    }}
                  />
                </Grid>
                {/* <Grid item sm={6} xs={12}>
                  <MDInput
                    value={_design?.background_colors?.buttons}
                    type="color"
                    label="Buttons "
                    fullWidth
                    className="color-input-2"
                    onChange={(e) => {
                      handleValues({
                        type: "background_colors",
                        name: "buttons",
                        value: e.target.value,
                      });
                    }}
                  />
                </Grid> */}
                <Grid item xs={12}>
                  <MDBox>
                    <MDTypography variant="h6">Text Colors</MDTypography>
                  </MDBox>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <MDInput
                    value={_design?.text_colors?.topbar}
                    type="color"
                    label="Topbar"
                    fullWidth
                    className="color-input-2"
                    onChange={(e) => {
                      handleValues({
                        type: "text_colors",
                        name: "topbar",
                        value: e.target.value,
                      });
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <MDInput
                    value={_design?.text_colors?.footer}
                    type="color"
                    label="Footer"
                    fullWidth
                    className="color-input-2"
                    onChange={(e) => {
                      handleValues({
                        type: "text_colors",
                        name: "footer",
                        value: e.target.value,
                      });
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <MDInput
                    value={_design?.text_colors?.sidebar_list}
                    type="color"
                    label="Sidebar List"
                    fullWidth
                    className="color-input-2"
                    onChange={(e) => {
                      handleValues({
                        type: "text_colors",
                        name: "sidebar_list",
                        value: e.target.value,
                      });
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <MDInput
                    value={_design?.text_colors?.sidebar_list_active}
                    type="color"
                    label="Sidebar List Active"
                    fullWidth
                    className="color-input-2"
                    onChange={(e) => {
                      handleValues({
                        type: "text_colors",
                        name: "sidebar_list_active",
                        value: e.target.value,
                      });
                    }}
                  />
                </Grid>
                {/* <Grid item sm={6} xs={12}>
                  <MDInput
                    value={_design?.text_colors?.buttons}
                    type="color"
                    label="Buttons"
                    fullWidth
                    className="color-input-2"
                    onChange={(e) => {
                      handleValues({
                        type: "text_colors",
                        name: "buttons",
                        value: e.target.value,
                      });
                    }}
                  />
                </Grid> */}
                <Grid item xs={12}>
                  <MDBox px={3}>
                    <MDTypography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      Connect white label domain
                    </MDTypography>
                    <MDTypography
                      variant="caption"
                      display="block"
                      sx={{ fontSize: "15px" }}
                      gutterBottom
                    >
                      Connect your own domain to the system{" "}
                      <b>arapp.yourdomain.com</b>
                    </MDTypography>
                    <MDTypography
                      variant="caption"
                      display="block"
                      sx={{ fontSize: "15px" }}
                      gutterBottom
                    >
                      You can use any domain you own or you can create a
                      sub-domain like arapp.yourdomain.com to get white-labeled
                      access to the platform.
                    </MDTypography>
                    <MDTypography
                      variant="caption"
                      display="block"
                      sx={{ fontSize: "15px" }}
                      gutterBottom
                    >
                      <b>1.</b> Refer to your domain registrar&apos;s control
                      panel, choose desired domain zone.
                    </MDTypography>
                    <MDTypography
                      variant="caption"
                      display="block"
                      sx={{ fontSize: "15px" }}
                      gutterBottom
                    >
                      <b>2.</b>Create a record of{" "}
                      <u>
                        <b>ANAME</b>
                      </u>{" "}
                      type, enter your domain/subdomain name and point it to{" "}
                      <u>
                        <b>209.97.191.238</b>
                      </u>
                    </MDTypography>
                    <MDTypography
                      variant="caption"
                      display="block"
                      sx={{ fontSize: "15px" }}
                      gutterBottom
                    >
                      <b>3.</b> We will do the rest!
                    </MDTypography>
                  </MDBox>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={5}>
              <MDBox position="sticky" top={10} width="auto">
                <MDBox
                  width="380px"
                  sx={{
                    borderRadius: "65px",
                    background: _design?.background_colors?.page,
                  }}
                  className="wallet_page_bg_preview"
                  component="img"
                  src={MobileImg}
                  alt="cellphone"
                />
                <MDBox position="absolute" top={20} left={11} width="356px">
                  <MDBox>
                    <Pass design={_design} resturant={resturant} />
                  </MDBox>
                </MDBox>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </Card>
    </DashboardLayout>
  );
};

export default index;
